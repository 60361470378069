<template>
  <ui-component-modal
    modalTitle="Change status"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingErrorMessage="errorMessage"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveYieldSettings"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="tabs is-centered has-margin-bottom is-toggle">
        <ul>
          <li :class="{ 'is-active': mYieldSettings.Status === 'Draft' }">
            <a @click="mYieldSettings.Status = 'Draft'">
              <span>Draft</span>
            </a>
          </li>
          <li :class="{ 'is-active': mYieldSettings.Status === 'Approved' }">
            <a @click="mYieldSettings.Status = 'Approved'">
              <span>Approved</span>
            </a>
          </li>
          <li :class="{ 'is-active': mYieldSettings.Status === 'Denied' }">
            <a @click="mYieldSettings.Status = 'Denied'">
              <span>Denied</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import yieldProvider from '@/providers/yield'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    yieldSettings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      saveStatus: 0,
      mYieldSettings: null,
    }
  },

  computed: {
    errorMessage() {
      let message = 'Oops, something went wrong.'

      if (this.saveStatus === 403) {
        message =
          'You do not have the correct rights for this location to change the status.'
      }
      if (this.saveStatus === 500) {
        message =
          'It is not you, it is us... some went wrong changing the data.'
      }

      return message
    },
  },

  created() {
    this.mYieldSettings = JSON.parse(JSON.stringify(this.yieldSettings))
  },

  methods: {
    saveYieldSettings() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        yieldProvider.methods
          .updateLocationYieldSettings(self.mYieldSettings)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.$emit('yieldSettingStatusChanged', self.mYieldSettings)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Yield setting status updated!',
              })

              self.onClickCancel()
            }
          })
          .catch((error) => {
            this.saveStatus = error.response.status

            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
